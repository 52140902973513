import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "router-history"
  }, [_c("el-tabs", {
    attrs: {
      type: "card",
      closable: ""
    },
    on: {
      "tab-click": _vm.changeTab,
      "tab-remove": _vm.removeTab
    },
    nativeOn: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.openContextMenu($event);
      }
    },
    model: {
      value: _vm.activeValue,
      callback: function callback($$v) {
        _vm.activeValue = $$v;
      },
      expression: "activeValue"
    }
  }, _vm._l(_vm.historys, function (item) {
    return _c("el-tab-pane", {
      key: item.trace_id,
      attrs: {
        label: item.tab_name ? item.tab_name : _vm.$t("route.".concat(item.name)),
        name: item.trace_id,
        tab: item
      }
    });
  }), 1), _vm._v(" "), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.contextMenuVisible,
      expression: "contextMenuVisible"
    }],
    staticClass: "contextmenu",
    style: {
      left: _vm.left + "px",
      top: _vm.top + "px"
    }
  }, [_c("li", {
    on: {
      click: _vm.closeAll
    }
  }, [_vm._v("Close All")]), _vm._v(" "), _c("li", {
    on: {
      click: _vm.closeLeft
    }
  }, [_vm._v("Close Left")]), _vm._v(" "), _c("li", {
    on: {
      click: _vm.closeRight
    }
  }, [_vm._v("Close Right")]), _vm._v(" "), _c("li", {
    on: {
      click: _vm.closeOther
    }
  }, [_vm._v("Close Other")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };